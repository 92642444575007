import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table, Tag} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/FinalSettlement/Components/BasicInfo";
import {FinalSettlementReq, FinalSettlementRow, FinalSettlementRowSummaryTotal} from "@services/dto/finalSettlement";
import {FinalSettlementService} from "@services/FinalSettlementService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderActionCom} from "@modules/FinalSettlement/Components/HeaderActionCom";
import {useLocation, useNavigate} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {parameterType, WindowPrint} from "@modules/WindowPrint";
import {TableFundApprovals} from "@components/TableFundApprovals";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {FundApproval} from "@modules/FundApproval";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProjectService} from "@services/ProjectService";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import dayjs from "dayjs";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  ONFILE,
  READY,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {TableFilingText} from "@components/TableFilingText";
import {ProcessRecords} from "@modules/ProcessRecords";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {CustomForm} from "@components/CustomForm";

const wideShowColumn: string[] = [
  'fsDate',
  'fsCode',
  'fsType',
  // 'fsDesc',
  'fsAmount-summation',
  // 'fsAccountPayId',
  // 'fsAccountInId',
  // 'fundingApprovals',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'fsStatus',
  'statusCommon',
  'action',
]

const narrowShowColumn: string[] = [
  'fsDate',
  'fsCode',
  'fsType',
  // 'fsDesc',
  // 'fsAmount-summation',
  // 'fsAccountPayId',
  // 'fsAccountInId',
  // 'fundingApprovals',
  'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'fsStatus',
  // 'statusCommon',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: FinalSettlementRow[] // 数据源
}

export const TableName = 'finalSettlement'

export const FinalSettlement = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    processStore,
    securityStore,
    finalSettlementStore,
    fundApprovalStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<FinalSettlementRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printTitle, setPrintTitle] = useState<string>('')
  const [fundApprovalOpen, setFundApprovalOpen] = useState<boolean>(false)
  const [fundApprovalDataSource, setFundApprovalDataSource] = useState<FundApprovalRow[]>([])
  const [finalSettlementRow, setFinalSettlementRow] = useState<FinalSettlementRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FinalSettlementRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [printDataSource, setPrintDataSource] = useState<FinalSettlementRow[]>([])
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<FinalSettlementRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<FinalSettlementRowSummaryTotal>({
    fsAmount: 0
  })
  const [searchReq, setSearchReq] = useState<FinalSettlementReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...finalSettlementStore.finalSettlementReq
  })

  const columns: ColumnsType<FinalSettlementRow> = [
    {
      title: '制单日期',
      dataIndex: 'fsDate',
      key: 'fsDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'fsCode',
      key: 'fsCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费类别',
      dataIndex: 'fsType',
      key: 'fsType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费摘要',
      dataIndex: 'fsDesc',
      key: 'fsDesc',
      width: TABLE_CELL_WIDTH_2x,
      // align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '申请金额(元)',
      dataIndex: 'fsAmount',
      key: 'fsAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'fsAccountPay',
      key: 'fsAccountPay',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'fsAccountIn',
      key: 'fsAccountIn',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundingApprovals',
      key: 'fundingApprovals',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableFundApprovals
            value={text as FundApprovalRow[]}
            onClick={() => {
              setFundApprovalDataSource(text)
              setFundApprovalOpen(true)
            }}></TableFundApprovals>
        </Space>
      )
    },
    {
      title: '关联税费计划',
      dataIndex: 'employeeFeeTaxScheduleCode',
      key: 'employeeFeeTaxScheduleCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'fsFileCode',
      key: 'fsFileCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.fsFile} value={record.fsFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'fsStatus',
      key: 'fsStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.fsStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.fsStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (finalSettlementStore.finalSettlementDatasource.data) {
      setDataSource(finalSettlementStore.finalSettlementDatasource.data.items || [])
      setTotal(finalSettlementStore.finalSettlementDatasource.data.total || 0)
    }
  }, [finalSettlementStore.finalSettlementDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (finalSettlementStore.finalSettlementRow?.finalSettlementId) {
      handleAdd(finalSettlementStore.finalSettlementRow)
      finalSettlementStore.updateFinalSettlementRow({
        ...finalSettlementStore.finalSettlementRow,
        finalSettlementId: undefined,
      })
    }
  }, [finalSettlementStore.finalSettlementRow])

  useEffect(() => {
    const objInitValue = {
      fsAmount: 0
    }
    let obj = summaryTotalObj
    const arr = finalSettlementStore.finalSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        fsAmount: accAdd(acc.fsAmount, cur.fsAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [finalSettlementStore.finalSettlementDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, finalSettlementStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleFundApprovalClick = async (record: FinalSettlementRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await FinalSettlementService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    // 注意责任部门 code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    // 构造资金审批
    fundApprovalStore.updateFundApprovalRow({
      id: undefined,
      code,
      fundApprovalId: row.id, // 合同结算发起资金审批
      bankStatementIds: undefined,
      bankStatementCodes: undefined,
      faDate: dayjs(row.fsDate), // 制单日期
      faCode: undefined, // 制单编号
      faType: undefined, // 款项类别
      faSummary: row.fsDesc, // 款项摘要
      faAmount: row.fsAmount, // 金额
      faPayDate: undefined, // 付款日期
      faStatus: undefined, // 办理进度
      faApplicationForm: row.id, // 关联申请id
      faApplicationFormType: '税费缴纳', // 关联申请类型
      fundingApprovalTables: [{type: '税费缴纳', businessId: row.id}],
      contractListIds: undefined,// 关联合同 商务合同
      contractLists: undefined,// 关联合同
      lnvoiceRegistration: undefined, // 关联发票登记
      lnvoiceRegistrationIds: undefined,
      personResponsibleName: row.personResponsibleName, // 办理人员
      personResponsible: row.personResponsible, // 办理人员
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject, // 责任部门
      cooperationUnit: ['N/A'], // 协作单位
      faFile: undefined, // 存档批号
      faFileList: undefined, // 上传文件

      faPaymentAccount: row.fsAccountPay,
      faPaymentAccountId: row.fsAccountPayId,
      baName1: row.baName1,
      baAccount1: row.baAccount1,
      baBlank1: row.baBlank1,

      faAccountsReceivable: row.fsAccountIn,
      faAccountsReceivableId: row.fsAccountInId,
      baName2: row.baName2,
      baAccount2: row.baAccount2,
      baBlank2: row.baBlank2,
    })
    navigate("/fundApproval")
  }

  // 打印单条数据
  const handlePrint = async (record: FinalSettlementRow) => {
    const list = await finalSettlementStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('税费缴纳单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  /**
   * @description 打印参数
   */
  const parameter: parameterType = {
    width: 21,
    height: 29.7,
    horizontal: 1.91,
    vertical: 2.54
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = () => {
    // 数据处理
    setPrintDataSource(finalSettlementStore.finalSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id)));
    setIsPrintTable(true)
    setPrintTitle('税费缴纳表格')
    setIsModalOpen(true)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: FinalSettlementReq) => {
    setSearchReq(req)
    finalSettlementStore.getFinalSettlement(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    finalSettlementStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const batchUpdate = (row: FinalSettlementRow) => {
    let flag = false
    const rows = finalSettlementStore.finalSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      if (selectedRow.fsStatus === ONFILE) {
        flag = true
        continue
      }
      if (row.fsDate && typeof row.fsDate === 'object') {
        selectedRow.fsDate = row.fsDate.format(DATE_FMT);
      }
      if (row.fsType) {
        selectedRow.fsType = row.fsType;
      }
      if (row.fsDesc) {
        selectedRow.fsDesc = row.fsDesc;
      }
      if (row.fsAmount || row.fsAmount === 0) {
        selectedRow.fsAmount = row.fsAmount;
      }
      if (row.fsAccountPayId) {
        selectedRow.fsAccountPayId = row.fsAccountPayId
        selectedRow.baName1 = row.baName1
        selectedRow.baAccount1 = row.baAccount1
        selectedRow.baBlank1 = row.baBlank1
        selectedRow.fsAccountPay = row.fsAccountPay
      }
      if (row.fsAccountInId) {
        selectedRow.fsAccountInId = row.fsAccountInId
        selectedRow.baName2 = row.baName2
        selectedRow.baAccount2 = row.baAccount2
        selectedRow.baBlank2 = row.baBlank2
        selectedRow.fsAccountIn = row.fsAccountIn
      }
      if (typeof row.fundApprovalId === 'object' && row.fundApprovalId) {
        selectedRow.fundApprovalId = row.fundApprovalId.join(',')
      }
      if (row.employeeFeeTaxScheduleId) {
        selectedRow.employeeFeeTaxScheduleId = row.employeeFeeTaxScheduleId
        selectedRow.employeeFeeTaxScheduleCode = row.employeeFeeTaxScheduleCode
        selectedRow.responsibilityProject = row.responsibilityProject
        selectedRow.cooperationUnit = row.cooperationUnit
        selectedRow.code = row.code;
      }
      if (row.fsFile) {
        selectedRow.fsFile = row.fsFile
      }
      if (row.personResponsible) {
        selectedRow.personResponsible = row.personResponsible
        selectedRow.personResponsibleName = row.personResponsibleName
      }
      if (row.fsStatus) {
        selectedRow.fsStatus = row.fsStatus
      }
      if (row.responsibilityProject) {
        selectedRow.responsibilityProject = row.responsibilityProject;
        selectedRow.code = row.code;
      }
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit.length) {
        selectedRow.cooperationUnit = row.cooperationUnit.join(',');
      }
    }
    if (flag) {
      message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    }
    FinalSettlementService.batchUpdateFinalSettlement(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  const handleAdd = (row?: FinalSettlementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      fsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      fsDate: dayjs(),
    } as FinalSettlementRow
    Object.assign(row || {}, initRow)
    setFinalSettlementRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: FinalSettlementRow) => {
    setFinalSettlementRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: FinalSettlementRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setFinalSettlementRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = finalSettlementStore.finalSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let fsStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, i, arr) => {
        const firstRow = arr[0]
        fsStatus = firstRow.fsStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.fsStatus !== firstRow.fsStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setFinalSettlementRow({fsStatus} as FinalSettlementRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: FinalSettlementRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [];
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        FinalSettlementService.deleteFinalSettlement(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(finalSettlementStore.finalSettlementReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: FinalSettlementRow) => {
    if (typeof row.fsDate === 'object' && row.fsDate) {
      row.fsDate = row.fsDate.format(DATE_FMT);
    }
    if (typeof row.fundApprovalId === 'object' && row.fundApprovalId) {
      row.fundApprovalId = row.fundApprovalId.join(',')
    }
    if (typeof row.cooperationUnit === 'object') {
      if (!row.id) {
        row.cooperationUnit = []
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await FinalSettlementService.updateFinalSettlement(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await FinalSettlementService.addFinalSettlement(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setFinalSettlementRow(undefined)
    refreshData(finalSettlementStore.finalSettlementReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...finalSettlementRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.fsStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.fsStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.fsDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.fsCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "税费缴纳",
          wnDesc: `您参与办理的税费缴纳（${subRow.fsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<FinalSettlementRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.fsCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "税费缴纳",
            wnDesc: `您参与办理的税费缴纳（${subRsp.data.fsCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = finalSettlementStore.finalSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.fsStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.fsStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.fsDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.fsCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "税费缴纳",
          wnDesc: `您参与办理的税费缴纳（${subRow.fsCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: FinalSettlementRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: FinalSettlementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      fsFile: '',
      fsStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      fsDate: dayjs(),
      fundApprovalId: []
    }
    setFinalSettlementRow(initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={finalSettlementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
          {
            key: 'handlePrintTable',
            icon: <EditOutlined/>,
            onClick: () => handlePrintTable()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={finalSettlementStore.finalSettlementDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={finalSettlementRow?.id}
          submitterId={submitterId}
          userId={finalSettlementRow?.personResponsible}
          status={finalSettlementRow?.fsStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      { /*关联资金审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联资金审批</div>}
        open={fundApprovalOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setFundApprovalOpen(false)}
        destroyOnClose
        footer={null}>
        <FundApproval
          dataSource={fundApprovalDataSource}
          isModalSelect
          isHiddenOptions
          locationPathname={'/fundApproval'}/>
      </CustomDraggableModal>

      <CustomForm
        title={'税费缴纳'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={finalSettlementRow?.personResponsible}
        status={finalSettlementRow?.fsStatus}
        isView={isView}
        extraDisabled={!finalSettlementRow?.id}
        extraOnClick={() => handlePrint(finalSettlementRow)}
        item={[
          {
            key: 'handleFundApprovalClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(finalSettlementRow?.fsStatus),
            onClick: () => handleFundApprovalClick(finalSettlementRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !finalSettlementRow?.id,
            onClick: () => handleCopyClick(finalSettlementRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !finalSettlementRow?.id,
            onClick: () => handleProcessRecordsClick(finalSettlementRow)
          },
        ]}>
        <BasicInfo row={{...finalSettlementRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      { /*打印*/ }
      <WindowPrint
        isHiddenTime
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        parameter={parameter}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>
    </div>
  )
}))
