import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {Any} from "@common/types";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";
import {SelectBankStatement} from "@components/SelectBankStatement";
import {BankStatementRow} from "@services/dto/bankStatement";
import {ContractListRow} from "@services/dto/contractList";
import {SelectFiling} from "@components/SelectFiling";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {ProjectRow} from "@services/dto/project";
import {ProjectService} from "@services/ProjectService";
import {FundApprovalService} from "@services/FundApprovalService";

export interface PropsType {
  row: FundApprovalRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const [faApplicationForm, setFaApplicationForm] = useState<string[]>()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()
  const [planCode, setPlanCode] = useState<string>()

  useEffect(() => {
    form.resetFields()
    const fundApprovalRow = {...props.row}
    filingStore.setFilingFaCode(fundApprovalRow.faCode)
    if (fundApprovalRow.fundingApprovalTables?.length) {
      fundApprovalRow.faApplicationFormType = fundApprovalRow.fundingApprovalTables[0].type
      fundApprovalRow.faApplicationForm = fundApprovalRow.fundingApprovalTables.map(o => o.businessId)
      setFaApplicationForm(fundApprovalRow.faApplicationForm)
    } else {
      fundApprovalRow.faApplicationFormType = ''
      fundApprovalRow.faApplicationForm = []
      setFaApplicationForm([])
    }
    if (fundApprovalRow.planType) {
      setPlanCode(fundApprovalRow.planCode)
    }
    if (typeof fundApprovalRow.faDate === 'string' && fundApprovalRow.faDate !== '') {
      fundApprovalRow.faDate = dayjs(fundApprovalRow.faDate)
    }
    if (typeof fundApprovalRow.faPayDate === 'string' && fundApprovalRow.faPayDate !== '') {
      fundApprovalRow.faPayDate = dayjs(fundApprovalRow.faPayDate)
    }
    if (typeof fundApprovalRow.cooperationUnit === 'string') {
      fundApprovalRow.cooperationUnit = fundApprovalRow.cooperationUnit !== '' ? fundApprovalRow.cooperationUnit.split(',') : []
    }
    if (typeof fundApprovalRow.contractLists === 'object') {
      fundApprovalRow.contractListIds = (fundApprovalRow.contractLists as ContractListRow[]).map(o => o.id)
    }
    if (typeof fundApprovalRow.bankStatementCodes === 'string') {
      fundApprovalRow.bankStatementCodes = fundApprovalRow.bankStatementCodes !== '' ? fundApprovalRow.bankStatementCodes.split(',') : []
      fundApprovalRow.bankStatementCodes = fundApprovalRow.bankStatementCodes.filter((v, i, self) => self.indexOf(v) === i)
    }
    if (typeof fundApprovalRow.bankStatementIds === 'string') {
      fundApprovalRow.bankStatementIds = fundApprovalRow.bankStatementIds !== '' ? fundApprovalRow.bankStatementIds.split(',') : []
      fundApprovalRow.bankStatementIds = fundApprovalRow.bankStatementIds.filter((v, i, self) => self.indexOf(v) === i)
    }
    setResponsibilityProject(fundApprovalRow.responsibilityProject)
    setCooperationUnit(fundApprovalRow.cooperationUnit)
    form.setFieldsValue(fundApprovalRow);
  }, [props.row.id])

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleFaPaymentAccountChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({faPaymentAccount: row?.baNick || value})
    form.setFieldsValue({baName1: row?.baName || value})
    form.setFieldsValue({baAccount1: row?.baAccount || value})
    form.setFieldsValue({baBlank1: row?.baBlank || value})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleFaAccountsReceivableChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({faAccountsReceivable: row?.baNick || value})
    form.setFieldsValue({baName2: row?.baName || value})
    form.setFieldsValue({baAccount2: row?.baAccount || value})
    form.setFieldsValue({baBlank2: row?.baBlank || value})
  }

  /**
   * 下拉批量选择资金出纳
   * @param valueArr
   * @param options
   */
  const handleBankStatementIdsChange = (valueArr: string[], options: BankStatementRow[]) => {
    const rows: string[] = options.filter(o => valueArr.includes(o.id)).map(o => o.bsCode)
    form.setFieldsValue({bankStatementCodes: rows})
  }

  const handleFaApplicationFormTypeChange = () => {
    setFaApplicationForm(undefined)
    form.resetFields(['faApplicationForm'])
  }

  const handleTableValueChange = async (v: string[], options: Any[]) => {
    if (options && options.length > 0) {
      const cooperationUnit = typeof options[0].cooperationUnit === 'string' ? options[0].cooperationUnit?.split(",") : options[0].cooperationUnit
      let responsibilityProject: ProjectRow
      if (options[0]?.responsibilityProject) {
        [responsibilityProject] = await ProjectService.getBatchByName([options[0]?.responsibilityProject])
      }
      setResponsibilityProject(options[0].personResponsible)
      setCooperationUnit(cooperationUnit)
      form.setFieldsValue({
        responsibilityProject: options[0].responsibilityProject,
        cooperationUnit: cooperationUnit,
        code: responsibilityProject?.code
      });

      switch (form.getFieldValue('faApplicationFormType')) {
      case '工资发放':
        form.setFieldsValue({
          // 付款账户
          faPaymentAccountId: options[0].paymentAccountId,
          faPaymentAccount: options[0].paymentAccountNick,
          baName1: options[0].paymentAccountTitle,
          baAccount1: options[0].paymentAccount,
          baBlank1: options[0].paymentAccountBank,
          // 收款账户
          faAccountsReceivableId: options[0].creditedAccountId,
          faAccountsReceivable: options[0].creditedAccountNick,
          baName2: options[0].creditedAccountTitle,
          baAccount2: options[0].creditedAccount,
          baBlank2: options[0].creditedAccountBank,
        });
        break
      /*case '员工借款':
        form.setFieldsValue({
          // 付款账户
          faPaymentAccountId: options[0].elReceiptNumber,
          faPaymentAccount: options[0].baNick2,
          baName1: options[0].baName2,
          baAccount1: options[0].baAccount2,
          baBlank1: options[0].baBlank2,
          // 收款账户
          faAccountsReceivableId: options[0].elPaymentNumber,
          faAccountsReceivable: options[0].baNick1,
          baName2: options[0].baName1,
          baAccount2: options[0].baAccount1,
          baBlank2: options[0].baBlank1,
        });
        break*/
      case '费用报销':
        form.setFieldsValue({
          // 付款账户
          faPaymentAccountId: options[0].efpPaymentAccount,
          faPaymentAccount: options[0].efpPaymentAccountName,
          baName1: options[0].efpName2,
          baAccount1: options[0].efpAccount2,
          baBlank1: options[0].efpBlank2,
          // 收款账户
          faAccountsReceivableId: options[0].efpAccountingObject,
          faAccountsReceivable: options[0].efpAccountingObjectName,
          baName2: options[0].efpName1,
          baAccount2: options[0].efpAccount1,
          baBlank2: options[0].efpBlank1,
        });
        break
      case '合同结算':
        form.setFieldsValue({
          // 付款账户
          faPaymentAccountId: options[0].csEndOutAccountId,
          faPaymentAccount: options[0].csEndOutAccount,
          baName1: options[0].csEndOutAccountTitle,
          baAccount1: options[0].csEndOutBankAccount,
          baBlank1: options[0].csEndOutDepositBank,
          // 收款账户
          faAccountsReceivableId: options[0].csEndInAccountId,
          faAccountsReceivable: options[0].csEndInAccount,
          baName2: options[0].csEndInAccountTitle,
          baAccount2: options[0].csEndInBankAccount,
          baBlank2: options[0].csEndInDepositBank,
        });
        break
      case '税费核算':
        form.setFieldsValue({
          // 付款账户
          faPaymentAccountId: options[0].bsAccountPayId,
          faPaymentAccount: options[0].baNick1,
          baName1: options[0].baName1,
          baAccount1: options[0].baAccount1,
          baBlank1: options[0].baBlank1,
          // 收款账户
          faAccountsReceivableId: options[0].bsAccountInId,
          faAccountsReceivable: options[0].baNick2,
          baName2: options[0].baName2,
          baAccount2: options[0].baAccount2,
          baBlank2: options[0].baBlank2,
        });
        break
      case '税费缴纳':
        form.setFieldsValue({
          // 付款账户
          faPaymentAccountId: options[0].fsAccountPayId,
          faPaymentAccount: options[0].fsAccountPay,
          baName1: options[0].baName1,
          baAccount1: options[0].baAccount1,
          baBlank1: options[0].baBlank1,
          // 收款账户
          faAccountsReceivableId: options[0].fsAccountInId,
          faAccountsReceivable: options[0].fsAccountIn,
          baName2: options[0].baName2,
          baAccount2: options[0].baAccount2,
          baBlank2: options[0].baBlank2,
        });
        break
      }

    } else {
      setResponsibilityProject(undefined)
      setCooperationUnit([])
      form.setFieldsValue({
        responsibilityProject: undefined,
        cooperationUnit: undefined,
        code: undefined,
        faPaymentAccountId: undefined,
        faPaymentAccount: undefined,
        baName1: undefined,
        baAccount1: undefined,
        baBlank1: undefined,
        faAccountsReceivableId: undefined,
        faAccountsReceivable: undefined,
        baName2: undefined,
        baAccount2: undefined,
        baBlank2: undefined,
      });
    }

    if (typeof v === 'object') {
      setFaApplicationForm(v)
      form.setFieldsValue({faApplicationForm: v})
      const faApplicationFormType = form.getFieldValue('faApplicationFormType')
      switch (faApplicationFormType) {
      case '工资发放':
        FundApprovalService.getFundingApprovalByType({pageNum: 1, perPage: 10, faApplicationFormType: '工资发放', faApplicationForm: v[0]}).then(rsp => {
          if (rsp.data) {
            form.setFieldsValue({
              planType: rsp.data.planType,
            });
            setPlanCode(rsp.data.planCode)
          }
        })
        break
      case '员工借款':
        FundApprovalService.getFundingApprovalByType({pageNum: 1, perPage: 10, faApplicationFormType: '员工借款', faApplicationForm: v[0]}).then(rsp => {
          if (rsp.data) {
            form.setFieldsValue({
              planType: rsp.data.planType,
            });
            setPlanCode(rsp.data.planCode)
          }
        })
        break
      case '费用报销':
        FundApprovalService.getFundingApprovalByType({pageNum: 1, perPage: 10, faApplicationFormType: '费用报销', faApplicationForm: v[0]}).then(rsp => {
          if (rsp.data) {
            form.setFieldsValue({
              planType: rsp.data.planType,
            });
            setPlanCode(rsp.data.planCode)
          }
        })
        break
      case '合同结算':
        FundApprovalService.getFundingApprovalByType({pageNum: 1, perPage: 10, faApplicationFormType: '合同结算', faApplicationForm: v[0]}).then(rsp => {
          if (rsp.data) {
            form.setFieldsValue({
              planType: rsp.data.planType,
            });
            setPlanCode(rsp.data.planCode)
          }
        })
        break
      case '税费核算':
        FundApprovalService.getFundingApprovalByType({pageNum: 1, perPage: 10, faApplicationFormType: '税费核算', faApplicationForm: v[0]}).then(rsp => {
          if (rsp.data) {
            form.setFieldsValue({
              planType: rsp.data.planType,
            });
            setPlanCode(rsp.data.planCode)
          }
        })
        break
      case '税费缴纳':
        FundApprovalService.getFundingApprovalByType({pageNum: 1, perPage: 10, faApplicationFormType: '税费缴纳', faApplicationForm: v[0]}).then(rsp => {
          if (rsp.data) {
            form.setFieldsValue({
              planType: rsp.data.planType,
            });
            setPlanCode(rsp.data.planCode)
          }
        })
        break
      default:
        break
      }
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      initialValues={{
        code: undefined,
        cooperationUnit: ['N/A']
      }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="faApplicationForm">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="faContractCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="bankStatementCodes">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="planCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        {
          /*props.row?.id && <Col span={24}>
            <Form.Item
              label="排序"
              name="sort"
              rules={[{required: false, message: '请输入排序!'}]}>
              <Input placeholder="请输入排序" allowClear/>
            </Form.Item>
          </Col>*/
        }
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="faDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择日期"}
              // showTime
              allowClear
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="制单编号"
              name="faCode"
              rules={[{required: false, message: '请输入制单编号!'}]}>
              <Input disabled placeholder="请输入制单编号"/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="款项类别"
            name="faType"
            rules={[{required: false, message: '请选择款项类别!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              code={'payment_typee'}
              placeholder={'请输入款项类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项摘要"
            name="faSummary"
            rules={[{required: false, message: '请输入款项摘要!'}]}>
            <Input.TextArea placeholder="请输入款项摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请金额"
            name="faAmount"
            rules={[{required: false, message: '请输入申请金额!'}]}>
            <InputNumber addonAfter={'元'} precision={2} placeholder="请输入申请金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="原付款账户"
            name="faPaymentAccount"
            rules={[{required: false, message: '请输入原付款账户!'}]}>
            <Input placeholder="请输入原付款账户"/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="faPaymentAccountId"
            rules={[{required: !props.isBatchUpdate, message: '请输入付款账户!'}]}>
            <SelectBankAccount
              onChange={handleFaPaymentAccountChange}
              disabled={form.getFieldValue('faApplicationFormType') !== '员工借款' || props.isView}
              allowClear
              placeholder={'请输入付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="baName1"
            rules={[{required: false, message: '付款账户请输入户名!'}]}>
            <Input placeholder="付款账户请输入户名" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户账号"
            name="baAccount1"
            rules={[{required: false, message: '请输入付款账户账号!'}]}>
            <Input placeholder="请输入付款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户银行"
            name="baBlank1"
            rules={[{required: false, message: '请输入付款账户银行!'}]}>
            <Input placeholder="请输入付款账户银行" disabled/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="原收款账户"
            name="faAccountsReceivable"
            rules={[{required: false, message: '请输入原收款账户!'}]}>
            <Input placeholder="请输入原收款账户"/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="faAccountsReceivableId"
            rules={[{required: !props.isBatchUpdate, message: '请输入收款账户!'}]}>
            <SelectBankAccount
              onChange={handleFaAccountsReceivableChange}
              disabled={form.getFieldValue('faApplicationFormType') !== '员工借款' || props.isView}
              allowClear
              placeholder={'请输入收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="baName2"
            rules={[{required: false, message: '请输入收款账户名称!'}]}>
            <Input placeholder="请输入收款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户账号"
            name="baAccount2"
            rules={[{required: false, message: '请输入收款账户账号!'}]}>
            <Input placeholder="请输入收款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户银行"
            name="baBlank2"
            rules={[{required: false, message: '请输入收款账户银行!'}]}>
            <Input placeholder="请输入收款账户银行" disabled/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="原关联申请表单类型"
            name="faApplicationFormType1"
            rules={[{required: false, message: '请输入原关联申请表单类型!'}]}>
            <Input disabled placeholder="请输入原关联申请表单类型"/>
          </Form.Item>
        </Col>*/ }
        { /*<Col span={24}>
          <Form.Item
            label="原关联申请表单"
            name="faApplicationForm1"
            rules={[{required: false, message: '请输入原关联申请表单!'}]}>
            <Input disabled placeholder="请输入原关联申请表单"/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="关联申请表单"
            name="faApplicationFormType"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联申请表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              allowClear
              isOption
              showKey={['工资发放', '员工借款', '费用报销', '合同结算', '税费核算', '税费缴纳']}
              isOnlyUnBindFa={[/*'工资发放',*/ /*'员工借款',*/ /*'费用报销',*/ /*'合同结算',*/ /*'税费核算',*/ /*'税费缴纳',*/ /*'资金出纳'*/]}
              mode={'multiple'}
              placeholder={'请选择关联申请表单'}
              tableValue={faApplicationForm}
              onChange={handleFaApplicationFormTypeChange}
              handleTableValueChange={handleTableValueChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联计划表单"
            name="planType"
            rules={[{required: false, message: '请选择关联计划表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              disabled
              allowClear
              isOption
              mode={'multiple'}
              placeholder={'请选择关联计划表单'}
              tableValue={planCode}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金出纳"
            name="bankStatementIds"
            rules={[{required: false, message: '请选择关联资金出纳!'}]}>
            <SelectBankStatement
              disabled
              className={'width-100-percentage'}
              mode={'multiple'}
              allowClear
              onChange={handleBankStatementIdsChange}
              placeholder={'请选择资金出纳'}
            ></SelectBankStatement>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              allowClear
              disabled
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear
              mode={'multiple'}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="faFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                isHidePlus
                isHideClose
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="faPayDate"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <DatePicker
              disabled
              // showTime
              allowClear
              placeholder={"请输入实现日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="bsAmountRealized"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <InputNumber
              min={0}
              precision={2}
              disabled
              addonAfter={'元'}
              placeholder="请输入实现金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="bsAmount"
            rules={[{required: false, message: '请输入余额!'}]}>
            <InputNumber
              min={0}
              precision={2}
              disabled
              placeholder="请输入余额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              fieldNames={{value: 'id', label: 'name'}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="faStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
